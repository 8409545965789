import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle } from 'lucide-react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Heart, MessageCircle, Sparkles, Star, ArrowRight } from 'lucide-react';

const Feature = ({ icon, title, description }) => (
  <motion.div 
    className="feature"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.6 }}
  >
    <div className="icon-wrapper">
      {typeof icon === 'string' ? (
        <img src={icon} alt={title} className="feature-image" />
      ) : (
        icon
      )}
    </div>
    <h3>{title}</h3>
    <p>{description}</p>
  </motion.div>
);

const PricingTier = ({ title, price, originalPrice, duration, features, isPopular, onClick }) => {
  return (
    <motion.div 
      className={`pricing-tier ${isPopular ? 'popular' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      onClick={onClick}
    >
      {isPopular && <div className="popular-badge">Most Popular</div>}
      <h3>{title}</h3>
      <p className="price">
        <span className="original-price">₹{originalPrice}</span>
        <span className="discounted-price">₹{price}</span>
        <span>/{duration}</span>
      </p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={20} />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button className="get-started-button">Get Started</button>
    </motion.div>
  );
};

const DynamicConversation = () => {
  const [conversation, setConversation] = useState({ chris: '', olivia: '' });
  const [pairIndex, setPairIndex] = useState(0);
  const [typing, setTyping] = useState('chris');

  useEffect(() => {
    const messagePairs = [
      {
        chris: "I'm nervous about asking them out.",
        olivia: "Good. That means it matters. Now, instead of worrying, let's craft a message that's impossible to ignore."
      },
      {
        chris: "I always mess up when texting someone I like. What should I do?",
        olivia: "Let's start by owning it. It's not about perfect texts, it's about making them want to keep the conversation going. I'll show you how."
      },
      {
        chris: "Hey, coffee went well. What's next?",
        olivia: "Great! Now's the time to keep the momentum going. Follow up, but don't overthink it. I'll help you send the perfect message to leave them wanting more."
      }
    ];

    let timer;
    const currentPair = messagePairs[pairIndex];

    const typeMessage = (sender, message, index = 0) => {
      if (index <= message.length) {
        setConversation(prev => ({
          ...prev,
          [sender]: message.slice(0, index)
        }));
        timer = setTimeout(() => typeMessage(sender, message, index + 1), 30);
      } else if (sender === 'chris') {
        setTyping('olivia');
        timer = setTimeout(() => typeMessage('olivia', currentPair.olivia), 500);
      } else {
        timer = setTimeout(() => {
          setPairIndex((pairIndex + 1) % messagePairs.length);
          setTyping('chris');
          setConversation({ chris: '', olivia: '' });
        }, 2000);
      }
    };

    typeMessage('chris', currentPair.chris);

    return () => clearTimeout(timer);
  }, [pairIndex]);

  return (
    <div className="dynamic-conversation">
      <AnimatePresence mode="wait">
        <motion.div
          key={pairIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <div className="chris-message">
            <span className="sender">Chris: </span>
            <span className="message">
              {conversation.chris}
              {typing === 'chris' && <span className="cursor chris" />}
            </span>
          </div>
          <div className="olivia-message">
            <span className="sender">Olivia: </span>
            <span className="message">
              {conversation.olivia}
              {typing === 'olivia' && <span className="cursor olivia" />}
            </span>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const LandingPage = ({ analytics }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const messages = [
    { user: "I'm nervous about my first date tomorrow...", ai: "Let's turn that anxiety into excitement. I'll guide you through proven strategies." },
    { user: "How do I make a great first impression?", ai: "I'll help you showcase your authentic self while making meaningful connections." },
    { user: "I keep matching with the wrong people...", ai: "Together we'll identify your patterns and align your matches with your values." }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessageIndex((prev) => (prev + 1) % messages.length);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  const auth = getAuth();
  
  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000); // Hide after 5 seconds
      }
    });

    return () => {
      unsubscribe();
      setShowNotification(false); // Reset notification state when component unmounts
    };
  }, [auth]);

  useEffect(() => {
    // Log page view
    if (analytics && typeof analytics.logEvent === 'function') {
      analytics.logEvent('page_view', {
        page_title: 'Landing Page',
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  }, [analytics]);

  const handleGetStarted = async () => {
    if (!user) {
      const provider = new GoogleAuthProvider();
      try {
        await signInWithPopup(auth, provider);
        // After successful sign-in, show a notification
        setShowNotification(true);
        return; // Exit the function here
      } catch (error) {
        console.error("Error signing in:", error);
        return;
      }
    }

    try {
      // Check subscription status after signing in
      const token = await user.getIdToken();
      const response = await axios.get('/.netlify/functions/api/check-subscription', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.hasActiveSubscription) {
        // Redirect to chat if subscription is active
        window.location.href = '/chat';
      } else {
        // Redirect to subscription page if no active subscription
        window.location.href = '/subscription';
      }
      // Optionally, handle the response (e.g., update state or show messages)
    } catch (error) {
      console.error("Error checking subscription:", error);
    }
  };

  return (
    <div className="landing-page">
      {/* Gradient Orbs */}
      <div className="gradient-orb orb-1" />
      <div className="gradient-orb orb-2" />
      
      {showNotification && (
        <div className="notification">
          <p>You have logged in! Click on "Start Your Journey"</p>
        </div>
      )}

      <header className="site-header">
        <div className="container">
          <div className="header-content">
            <div className="logo">
              <h1>Olivia AI</h1>
            </div>
            <nav className="nav-links">
              <button onClick={() => {
                const pricingSection = document.getElementById('pricing-section');
                pricingSection?.scrollIntoView({ behavior: 'smooth' });
              }}>Pricing</button>
              <button onClick={handleGetStarted} className="sign-up-btn">Sign Up</button>
            </nav>
          </div>
        </div>
      </header>

      <main>
        <section className="hero">
          <div className="container">
            <div className="hero-content">
              {/* Left Column - Text Content */}
              <div className="hero-text">
                <span className="badge">AI-Powered Dating Coach</span>
                <motion.h1 
                  className="hero-heading"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  style={{ textAlign: 'left' }}
                >
                  Master the Art of Dating with AI
                </motion.h1>
                <motion.p 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  Boost your Relationship game with personalized advice & strategies
                </motion.p>
                <motion.button
                  className="cta-button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleGetStarted}
                >
                  {user ? 'Start Your Journey' : 'Sign In to Get Started'}
                </motion.button>
              </div>

              {/* Right Column - Chat Preview */}
              <div className="chat-preview">
                <div className="chat-card">
                  {/* Chat Header */}
                  <div className="chat-header">
                    <div className="ai-status">
                      <div className="status-dot" />
                      <span>AI Coach Active</span>
                    </div>
                  </div>
                  
                  {/* Chat Messages */}
                  <div className="chat-messages">
                    <AnimatePresence mode="wait">
                      <motion.div
                        key={currentMessageIndex}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.5 }}
                        className="message-group"
                      >
                        <div className="message user-message">
                          <p>{messages[currentMessageIndex].user}</p>
                        </div>
                        <div className="message ai-message">
                          <p>{messages[currentMessageIndex].ai}</p>
                        </div>
                      </motion.div>
                    </AnimatePresence>
                  </div>

                  {/* Chat Input */}
                  <div className="chat-input">
                    <input type="text" placeholder="Type your message..." />
                    <button className="send-button" style={{ width: '45px', height: '45px' }}>
                      <ArrowRight size={24} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose-section">
          <div className="container">
            <h2 className="section-heading">Why Choose Olivia?</h2>
            <div className="features-grid">
              <div className="feature-card">
                <div className="feature-image">
                  <img src="olivia.jpg" alt="Personalized Advice" />
                </div>
                <h3>Personalized Advice</h3>
                <p>Get tailored strategies based on your personality and goals from Olivia</p>
              </div>
              
              <div className="feature-card">
                <div className="feature-image">
                  <img src="olivia1.jpg" alt="24/7 Support" />
                </div>
                <h3>24/7 Support</h3>
                <p>Olivia is always here to help you navigate your whole journey</p>
              </div>
              
              <div className="feature-card">
                <div className="feature-image">
                  <img src="olivia2.jpg" alt="Boost Confidence" />
                </div>
                <h3>Boost Confidence</h3>
                <p>Learn techniques to approach Relationships with confidence and authenticity</p>
              </div>
            </div>
          </div>
        </section>

        <section className="testimonials">
          <div className="container">
            <h2>What Our Users Say</h2>
            <div className="testimonials-grid">
              <div className="testimonial-item">
                <p className="quote">"As a introvert Olivia helped me overcome my Relationship anxiety!"</p>
                <p className="author">- Varun, 23</p>
              </div>
              <div className="testimonial-item">
                <p className="quote">"The personalized advice was game-changing. I feel so much more confident now."</p>
                <p className="author">- Sam, 24</p>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing-section" className="pricing" style={{
          padding: '80px 24px',
          background: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(10px)',
        }}>
          <div style={{
            maxWidth: '1200px',
            margin: '0 auto',
          }}>
            <h2 style={{
              fontSize: '42px',
              textAlign: 'center',
              marginBottom: '60px',
              color: '#E838CC',
              fontWeight: '500',
            }}>
              Choose Your Plan
            </h2>
            <div style={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              gap: '24px',
              justifyContent: 'center',
              alignItems: isSmallScreen ? 'center' : 'stretch',
            }}>
              <div style={{
                background: 'rgba(31, 27, 46, 0.5)',
                borderRadius: '16px',
                padding: '32px',
                flex: '1',
                maxWidth: '400px',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
              }}>
                <h3 style={{ fontSize: '24px', marginBottom: '24px', fontWeight: '500' }}>3 Months Access</h3>
                <div style={{ marginBottom: '24px' }}>
                  <div style={{ 
                    color: 'rgba(255, 255, 255, 0.5)',
                    textDecoration: 'line-through',
                    fontSize: '14px',
                  }}>₹3,999</div>
                  <div style={{ 
                    fontSize: '36px',
                    fontWeight: '600',
                    marginTop: '4px',
                  }}>₹999</div>
                </div>
                <ul style={{ 
                  listStyle: 'none',
                  padding: '0',
                  margin: '32px 0',
                }}>
                  {['Personalized advice', '100 daily messages', 'No generic BS', '24/7 chat support'].map((feature) => (
                    <li key={feature} style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '14px',
                    }}>
                      <CheckCircle size={16} style={{ color: '#4CAF50', marginRight: '12px' }} />
                      {feature}
                    </li>
                  ))}
                </ul>
                <div style={{ marginTop: '67px' }}>
                  <button style={{
                    width: '100%',
                    padding: '12px',
                    borderRadius: '8px',
                    border: 'none',
                    background: 'rgba(255, 255, 255, 0.1)',
                    color: '#fff',
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                  }} onClick={handleGetStarted}>
                    Get Started <ArrowRight size={14} />
                  </button>
                </div>
              </div>

              <div style={{
                background: 'rgba(31, 27, 46, 0.8)',
                borderRadius: '16px',
                padding: '32px',
                flex: '1',
                maxWidth: '400px',
                border: '1px solid rgba(255, 255, 255, 0.15)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)',
                position: 'relative',
              }}>
                <div style={{
                  position: 'absolute',
                  top: '-10px',
                  right: '20px',
                  background: 'linear-gradient(to right, #E838CC, #8F44FD)',
                  padding: '4px 12px',
                  borderRadius: '20px',
                  fontSize: '12px',
                  fontWeight: '500',
                }}>
                  Most Popular
                </div>
                <h3 style={{ fontSize: '24px', marginBottom: '24px', fontWeight: '500' }}>Lifetime Access</h3>
                <div style={{ marginBottom: '24px' }}>
                  <div style={{ 
                    color: 'rgba(255, 255, 255, 0.5)',
                    textDecoration: 'line-through',
                    fontSize: '14px',
                  }}>₹11,000</div>
                  <div style={{ 
                    fontSize: '36px',
                    fontWeight: '600',
                    marginTop: '4px',
                  }}>₹4,999</div>
                </div>
                <ul style={{ 
                  listStyle: 'none',
                  padding: '0',
                  margin: '32px 0',
                }}>
                  {['Everything in 3 Months', 'Increased messaging limits', 'Priority support', 'Future updates included', 'Exclusive resources'].map((feature) => (
                    <li key={feature} style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                      color: 'rgba(255, 255, 255, 0.7)',
                      fontSize: '14px',
                    }}>
                      <CheckCircle size={16} style={{ color: '#4CAF50', marginRight: '12px' }} />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button style={{
                  width: '100%',
                  padding: '12px',
                  borderRadius: '8px',
                  border: 'none',
                  background: 'linear-gradient(to right, #E838CC, #8F44FD)',
                  color: '#fff',
                  fontSize: '14px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                }} onClick={handleGetStarted}>
                  Get Started <ArrowRight size={14} />
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className="container">
          <p>&copy; 2024 Phymech. All rights reserved.</p>
          <div className="footer-links">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-of-service">Terms of Service</Link>
           <Link to="/contact-us">Contact Us</Link>
           <Link to="/about-us">About Us</Link>
          </div>
        </div>
      </footer>

      <style>{`
        /* Reset and base styles */
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        body {
          font-family: 'Inter', sans-serif;
          line-height: 1.5;
          color: #ffffff;
          background-color: #0A0118;
        }

        .landing-page {
          min-height: 100vh;
          background-color: #0A0118;
          color: #fff;
          position: relative;
          overflow-x: hidden;
          padding: 0;
        }

        .container {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 24px;
        }

        /* Gradient Orbs */
        .gradient-orb {
          position: absolute;
          border-radius: 50%;
          filter: blur(100px);
        }

        .orb-1 {
          top: 0;
          left: 25%;
          width: 800px;
          height: 800px;
          background-color: rgba(147, 51, 234, 0.2);
          transform: translate(-50%, -50%);
        }

        .orb-2 {
          top: 50%;
          right: 0;
          width: 600px;
          height: 600px;
          background-color: rgba(236, 72, 153, 0.2);
          transform: translateX(50%);
        }

        /* Hero Section */
        .hero {
          min-height: calc(100vh - 80px);
          display: flex;
          align-items: center;
          position: relative;
        }

        .hero-content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 64px;
          align-items: center;
        }

        .hero-text {
          max-width: 600px;
        }

        .badge {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.1);
          color: #E879F9;
          padding: 8px 16px;
          border-radius: 9999px;
          font-size: 14px;
          margin-bottom: 24px;
          backdrop-filter: blur(10px);
          border: 1px solid rgba(147, 51, 234, 0.2);
        }

        h1 {
          font-size: 64px;
          font-weight: bold;
          line-height: 1.1;
          margin-bottom: 16px;
          background: linear-gradient(to right, #E879F9, #EC4899);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .hero-text p {
          font-size: 20px;
          color: #a1a1aa;
          margin-bottom: 32px;
        }

        .cta-button {
          background: linear-gradient(to right, #9333EA, #EC4899);
          color: #ffffff;
          font-size: 18px;
          padding: 16px 32px;
          border: none;
          border-radius: 9999px;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 10px 15px -3px rgba(147, 51, 234, 0.3);
        }

        .cta-button:hover {
          opacity: 0.9;
          transform: translateY(-2px);
        }

        /* Chat Preview */
        .chat-preview {
          position: relative;
          width: 100%;
          max-width: 500px;
          margin: 0 auto;
        }

        .chat-card {
          background-color: rgba(255, 255, 255, 0.03);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 16px;
          overflow: hidden;
          backdrop-filter: blur(20px);
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        }

        .chat-header {
          padding: 16px 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          background-color: rgba(255, 255, 255, 0.05);
        }

        .ai-status {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #fff;
        }

        .status-dot {
          width: 12px;
          height: 12px;
          background-color: #E879F9;
          border-radius: 50%;
          animation: pulse 2s infinite;
        }

        @keyframes pulse {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(232, 121, 249, 0.7);
          }
          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(232, 121, 249, 0);
          }
          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(232, 121, 249, 0);
          }
        }

        .chat-messages {
          padding: 24px;
          min-height: 300px;
          max-height: 400px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .message-group {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .message {
          max-width: 85%;
          padding: 16px;
          border-radius: 16px;
          word-wrap: break-word;
        }

        .user-message {
          align-self: flex-end;
          background: linear-gradient(to right, rgba(147, 51, 234, 0.3), rgba(236, 72, 153, 0.3));
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .ai-message {
          align-self: flex-start;
          background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .chat-input {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 16px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          background-color: rgba(255, 255, 255, 0.05);
        }

        .chat-input input {
          flex-grow: 1;
          background-color: rgba(255, 255, 255, 0.05);
          border: none;
          color: #ffffff;
          font-size: 16px;
          padding: 12px 16px;
          border-radius: 9999px;
        }

        .chat-input input::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }

        .chat-input input:focus {
          outline: none;
          background-color: rgba(255, 255, 255, 0.1);
        }

        .send-button {
          background: linear-gradient(to right, #9333EA, #EC4899);
          color: #ffffff;
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: opacity 0.3s ease;
        }

        .send-button:hover {
          opacity: 0.9;
        }

        /* Features Section */
        .features {
          padding: 80px 0;
          background-color: #0A0118;
          position: relative;
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 40px;
          margin-top: 60px;
        }

        .feature-item {
          text-align: center;
          padding: 20px;
        }

        .feature-image {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 8px;
          margin-bottom: 24px;
        }

        .feature-item h3 {
          font-size: 24px;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 16px;
        }

        .feature-item p {
          font-size: 16px;
          line-height: 1.6;
          color: rgba(255, 255, 255, 0.7);
        }

        /* Testimonials Section */
        .testimonials {
          padding: 100px 0;
          background-color: #0A0118;
          position: relative;
        }

        .testimonials h2 {
          font-size: 48px;
          text-align: center;
          margin-bottom: 60px;
          background: linear-gradient(to right, #E879F9, #EC4899);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .testimonials-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 40px;
          max-width: 1000px;
          margin: 0 auto;
        }

        .testimonial-item {
          padding: 32px;
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.03);
          backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .testimonial-item .quote {
          font-size: 20px;
          line-height: 1.6;
          color: #ffffff;
          margin-bottom: 20px;
        }

        .testimonial-item .author {
          font-size: 16px;
          color: #E879F9;
          font-weight: 500;
        }

        /* Pricing Section */
        .pricing {
          padding: 100px 0;
          background-color: #0A0118;
          position: relative;
        }

        .pricing .container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 40px;
        }

        .pricing h2 {
          font-size: 48px;
          text-align: center;
          margin-bottom: 60px;
          background: linear-gradient(to right, #E879F9, #EC4899);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .pricing-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px;
          max-width: 1000px;
          margin: 0 auto;
        }

        .pricing-card {
          background: rgba(255, 255, 255, 0.02);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 24px;
          padding: 40px;
          position: relative;
          backdrop-filter: blur(10px);
        }

        .pricing-card.popular {
          background: rgba(147, 51, 234, 0.05);
          border: 1px solid rgba(147, 51, 234, 0.2);
        }

        .popular-badge {
          position: absolute;
          top: 20px;
          right: 20px;
          background: linear-gradient(to right, #9333EA, #EC4899);
          color: white;
          font-size: 14px;
          padding: 8px 16px;
          border-radius: 100px;
        }

        .pricing-card h3 {
          font-size: 28px;
          color: white;
          margin-bottom: 32px;
        }

        .price-container {
          text-align: center;
          margin-bottom: 40px;
        }

        .original-price {
          color: rgba(255, 255, 255, 0.5);
          text-decoration: line-through;
          font-size: 18px;
          margin-bottom: 8px;
        }

        .current-price {
          font-size: 48px;
          font-weight: 700;
          color: white;
          margin-bottom: 4px;
        }

        .duration {
          color: rgba(255, 255, 255, 0.5);
          font-size: 16px;
        }

        .pricing-card ul {
          list-style: none;
          margin: 0 0 40px;
          padding: 0;
        }

        .pricing-card li {
          display: flex;
          align-items: center;
          gap: 12px;
          color: rgba(255, 255, 255, 0.9);
          margin-bottom: 16px;
          font-size: 16px;
        }

        .check-icon {
          width: 20px;
          height: 20px;
          color: #10B981;
        }

        .get-started-button {
          width: 100%;
          background: rgba(255, 255, 255, 0.05);
          color: white;
          border: none;
          border-radius: 100px;
          padding: 16px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }

        .pricing-card.popular .get-started-button {
          background: linear-gradient(to right, #9333EA, #EC4899);
        }

        .get-started-button:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .pricing-card.popular .get-started-button:hover {
          opacity: 0.9;
        }

        @media (max-width: 768px) {
          .pricing {
            padding: 60px 0;
            flex-direction: column;
            
          }

          .pricing .container {
            padding: 0 20px;
            
          }

          .pricing h2 {
            font-size: 36px;
            margin-bottom: 40px;
          }

          .pricing-grid {
            grid-template-columns: 1fr;
            gap: 24px;
          }

          .pricing-card {
            padding: 30px;
            flex-direction: column;
          }

          .current-price {
            font-size: 36px;
          }
        }

        @media (max-width: 768px) {
          .pricing {
            padding: 40px 0;
            flex-direction: column;
          }

          .pricing h2 {
            font-size: 28px;
            margin-bottom: 30px;
          }

          .pricing-card {
            padding: 24px;
          }

          .current-price {
            font-size: 32px;
          }

          .pricing-card li {
            font-size: 14px;
          }

          .get-started-button {
            padding: 14px;
            font-size: 14px;
          }
        }

        /* Footer */
        footer {
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          padding: 48px 0;
          position: relative;
        }

        footer .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          position: relative;
          z-index: 1;
        }

        footer p {
          color: #a1a1aa;
        }

        .footer-links {
          display: flex;
          gap: 24px;
        }

        .footer-links a {
          color: #a1a1aa;
          text-decoration: none;
          transition: color 0.3s ease;
        }

        .footer-links a:hover {
          color: #E879F9;
        }

        /* Additional Media Queries */
        @media (max-width: 768px) {
          .features-grid,
          .pricing-grid,
          .testimonials-grid {
            grid-template-columns: 1fr;
            padding: 0 16px;
          }
          
          .features,
          .pricing,
          .testimonials {
            padding: 64px 0;
          }

          .features h2,
          .pricing h2,
          .testimonials h2 {
            font-size: 28px;
            margin-bottom: 40px;
          }

          .pricing-card,
          .feature-item,
          .testimonial-item {
            padding: 20px;
          }

          .footer-links {
            flex-direction: raw;
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            padding: 0 20px;
          
          }

          .footer-links a {
            margin: 8px 0;
            margin: 0;
            font-size: 14px;
          }
        }

        @media (max-width: 480px) {
          .features,
          .pricing,
          .testimonials {
            padding: 48px 0;
          }

          .feature-item,
          .pricing-card,
          .testimonial-item {
            padding: 16px;
          }

          .current-price {
            font-size: 28px;
          }

          .quote {
            font-size: 16px;
          }
        }

        /* Why Choose Olivia Section */
        .why-choose-section {
          padding: 80px 0;
          background: rgba(255, 255, 255, 0.02);
          backdrop-filter: blur(10px);
        }

        .section-heading {
          text-align: center;
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 48px;
          background: linear-gradient(to right, #E838CC, #8F44FD);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .features-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 32px;
          max-width: 1000px;
          margin: 0 auto;
        }

        .feature-card {
          text-align: center;
          padding: 24px;
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.05);
          backdrop-filter: blur(10px);
          transition: transform 0.3s ease;
        }

        .feature-card:hover {
          transform: translateY(-5px);
        }

        .feature-image {
          width: 160px;
          height: 160px;
          margin: 0 auto 24px;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid rgba(255, 255, 255, 0.1);
        }

        .feature-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .feature-card h3 {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 12px;
          background: linear-gradient(to right, #E838CC, #8F44FD);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .feature-card p {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.7);
          line-height: 1.6;
        }

        @media (max-width: 768px) {
          .features-grid {
            grid-template-columns: 1fr;
            gap: 24px;
            padding: 0 24px;
          }
          
          .feature-image {
            width: 140px;
            height: 140px;
          }
        }

        /* Notification */
        .notification {
          position: fixed;
          top: 20px;
          right: 20px;
          background: linear-gradient(to right, #9333EA, #EC4899);
          color: white;
          padding: 16px 24px;
          border-radius: 12px;
          box-shadow: 0 8px 16px rgba(147, 51, 234, 0.2);
          z-index: 1000;
          animation: slideIn 0.5s ease-out;
        }

        @keyframes slideIn {
          from {
            transform: translateX(100%);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }

        /* Site Header */
        .site-header {
          position: relative;
          padding: 16px 0;
          margin-top: 25px;
        }

        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .logo h1 {
          font-size: 24px;
          font-weight: 600;
          background: linear-gradient(to right, #E838CC, #8F44FD);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0;
        }

        .nav-links {
          display: flex;
          gap: 24px;
          align-items: center;
        }

        .nav-links button {
          background: none;
          border: none;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
          padding: 8px 16px;
          border-radius: 8px;
          transition: all 0.3s ease;
        }

        .nav-links button:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .nav-links .sign-up-btn {
          background: linear-gradient(to right, #E838CC, #8F44FD);
          color: #fff;
        }

        .nav-links .sign-up-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(232, 56, 204, 0.3);
        }

        /* Add padding to main to account for fixed header */
        main {
          padding-top: 0;
        }

        @media (max-width: 768px) {
          .nav-links {
            gap: 16px;
          }
          
          .nav-links button {
            font-size: 14px;
            padding: 6px 12px;
          }
          
          .logo h1 {
            font-size: 20px;
          }
        }

        @media (max-width: 768px) {
          .hero-content {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .hero-text {
            text-align: center;
          }

          .chat-preview {
            width: 100%;
            max-width: none;
          }

          .chat-card {
            width: 100%;
          }

          .pricing-grid {
            grid-template-columns: 1fr;
            padding: 0 20px;
          }

          .pricing-card {
            padding: 30px 20px;
          }

          .pricing-card h3 {
            font-size: 24px;
            margin-bottom: 24px;
          }

          .current-price {
            font-size: 36px;
          }

          .features-grid {
            grid-template-columns: 1fr;
          }

          .testimonials-grid {
            grid-template-columns: 1fr;
          }

          .footer-links {
            flex-direction: raw;
            align-items: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            padding: 0 20px;
          
          }

          .footer-links a {
            margin: 8px 0;
            margin: 0;
            font-size: 14px;
          }
        }

        @media (max-width: 768px) {
          .landing-page {
            padding: 0 1rem;
          }

          .header-content {
            flex-direction: column;
            gap: 1rem;
            padding: 1rem 0;
          }

          .nav-links {
            width: 100%;
            justify-content: center;
            gap: 1rem;
          }

          .hero-content {
            flex-direction: column;
            padding: 2rem 0;
          }

          .hero-text {
            width: 100%;
            text-align: center;
          }

          .hero-heading {
            font-size: 2rem !important;
            text-align: center !important;
          }

          .chat-preview {
            width: 100%;
            margin-top: 2rem;
          }

          .chat-card {
            width: 100%;
            max-width: 100%;
          }

          .pricing-tiers {
            flex-direction: raw;
            gap: 2rem;
            
          }

          .pricing-tier {
            width: 100%;
            max-width: none;
          }

          .features-grid {
            grid-template-columns: 1fr;
            gap: 2rem;
            padding: 2rem 1rem;
          }

          .feature {
            text-align: center;
          }

          .testimonials {
            padding: 2rem 1rem;
          }

          .testimonial-card {
            width: 100%;
          }

          .cta-section {
            padding: 3rem 1rem;
          }

          .footer {
            padding: 2rem 1rem;
          }
        }

        @media (max-width: 768px) {
          footer {
            padding: 24px 0;
            text-align: center;
          }

          footer .container {
            flex-direction: raw;
            align-items: center;
            gap: 16px;
          }

          .footer-links {
            flex-direction: raw;
            align-items: center;
            gap: 8px;
          }

          .footer-links a {
            margin: 0;
          }
        }

        
      `}</style>
    </div>
  );
};

export default LandingPage;